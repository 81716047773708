































import priceCalculator from '@/service/price-calculator';
import Vue from 'vue';

export default Vue.extend({
  name: 'contract-finish',
  data: () => ({
    token: '',
    isTokenValid: false,
    loading: true,
    insuranceId: 0,
    isTokenActivated: false,
    idWithYear: '',
  }),
  async created() {
    this.initToken();
    await this.checkToken();
  },
  methods: {
    initToken() {
      const { token } = this.$route.query;
      this.token = typeof token === 'string' ? token : '';
    },
    async checkToken() {
      if (!this.token) {
        return;
      }
      try {
        this.isTokenValid = await this.checkExpiringDate(this.token);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async checkExpiringDate(token: string) {
      const result = await priceCalculator.checkExpiringToken(token);
      this.insuranceId = result.data.insuranceId;
      this.isTokenActivated = result.data.isTokenActivated;
      this.idWithYear = result.data.idWithYear;
      return result.data.success;
    },
    async finishContract() {
      console.log(this.insuranceId);
      const result = await priceCalculator.finishContract({
        insurance: this.insuranceId,
        token: this.token,
      });
      this.isTokenActivated = result.data.success;
    },
  },
});
